import numbro from 'numbro'
import cheerio from 'cheerio'
import config from 'config'
export const isNonZeroValue = (value) => !/^(0(\.0{1,2})?)$/.test(value)

export const getNumber = (value) => {
  if (!value) return 0
  switch (typeof (value)) {
    case 'number':
      value = Number(value)
      break;
    case 'string':
      value = Number(value.trim().replace(/[^0-9\.]+/g, ''))
      break;

    default:
      value = 0
      break;
  }

  if (Number.isNaN(value)) return 0
  return value || 0
}

// export const isNumber = (value) => /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/g.test(value ? value.toString() : "");
// include support for 0002 so you can type stuff
export const isNumber = (value) => /^0+$|^(0|[1-9]\d*)$|^0*\.\d+$|^(0|[1-9]\d*)\.\d*$/.test(value ? value.toString() : '');

export const formatNumber = (value, pattern = '0,0.00') => {
  if (!value) return '0.00'
  return numbro(getNumber(value)).format(pattern)
}

export const getDiscountedAmount = (price, coupon) => {
  if (!coupon) return price;
  // console.log('getDiscountedAmount', price, coupon)
  let discountedPrice = price;
  const fprice = (price * 100).toFixed()

  
  if (coupon.amount_off) {
    // Subtract fixed amount from the price
    discountedPrice -= (coupon.amount_off / 100)
  } else if (coupon.percent_off) {
    // Calculate discount based on percentage
    discountedPrice -= ((fprice * (coupon.percent_off / 100)).toFixed()) / 100
  }
  if (discountedPrice >= price) {
    discountedPrice = price
  }
  // Ensure the result is not negative
  return Math.max(0, parseFloat(Number(discountedPrice).toFixed(2)))
}
export const getJoinButtonText = (trialPeriod) => trialPeriod && trialPeriod !== '0' ? 'Start Your Trial' : 'Join Now'

const isValidScalingFactor = (target) => {
  if (target === 1 || target < 0 || target === 0) {
    return false;
  }
  // Number must start with "1" and have zeroes after
  return target.toString().match(/^1(0*)$/);
}

export const scaledIntToDecimal = (num, scalingFactor = 100) => {
  if (num === 0) {
    return '0.00';
  }
  if (!num || Number.isNaN(Number(num))) {
    throw new Error(`Invalid number format, number: ${num}`);
  }

  if (!isValidScalingFactor(scalingFactor)) {
    throw new Error('Invalid scaling factor. Must start with 1 and have zeroes after.');
  }

  // Calculate decimal places based on scaling factor:
  const decimalPlaces = Math.log10(scalingFactor);

  // Extract integer and decimal parts using substring:
  const scaledIntegerString = String(num);
  const integerPart = scaledIntegerString.substring(0, scaledIntegerString.length - decimalPlaces);
  const decimalPart = scaledIntegerString.substring(scaledIntegerString.length - decimalPlaces);

  return `${integerPart}.${decimalPart}`;
}


export const formatPrice = (value, currency, denomination = 1) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  })

  try {
    if (!value) return formatter.format('0.00')
    const s = getNumber(value).toString()
    const hasDecimal = s.substring(0, s.indexOf('.'))
    let price = s
    if (hasDecimal) {
      price = s.substring(0, s.indexOf('.') + 3)
    }

    return formatter.format(price)
  } catch (error) {
    console.log('Error occurred while formatting price:', error, currency, value, error.toString())
    return formatter.format('0.00')
  }
}

export const isValidVideoURL = (url) => {
  const patterns = {
    youtube: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
    vimeo: /^(https?:\/\/)?(www\.)?vimeo\.com\/.+$/,
  }

  return Object.values(patterns).some((regex) => regex.test(url))
}

export const validateVideoURL = (url) => {
  const isValid = url?.length && isValidVideoURL(url)

  return isValid ? 'Valid URL' : 'Invalid URL'
}

export const isSemanticVersion = (version) => {
  const versionRegex = /^\d+\.\d+\.\d+$/
  return versionRegex.test(version)
}

export const compareVersions = (version1, version2) => {
  if (!isSemanticVersion(version1) || !isSemanticVersion(version2)) {
    return NaN
  }

  const v1 = version1.split('.').map(Number)
  const v2 = version2.split('.').map(Number)

  for (let i = 0; i < v1.length; i++) {
    if (v1[i] > v2[i]) {
      return 1
    } if (v1[i] < v2[i]) {
      return -1
    }
  }

  return 0
}

export const isVersionGreaterOrEqual = (version1, version2) => !!(version1 && version2 && compareVersions(version1, version2) >= 0)

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getFormattedPrices = (page, stripeCoupon = null, yearlySelected) => {
  const currency = page.currency || 'usd';
  // console.log('getFormattedPrices', page, yearlySelected)
  // For empty pages or free periods return empty object
  if (!page.price || page.period === 'free') {
    return {};
  }
  // console.log('allow renewal', page.customSetting1Enabled)

  const basePrice = scaledIntToDecimal(page.price);
  const formattedPrices = {
    
    price: formatPrice(basePrice, currency),
    currency,
    period: page.period,
    coupon: stripeCoupon
  };

  // Helper function to calculate discounted price
  const calculateDiscountedPrice = (price, coupon) => {
    if (!coupon || !coupon.valid) return price;
    return getDiscountedAmount(price, coupon);
  };

  // Calculate monthly and yearly prices before applying coupon
  let monthlyPrice, yearlyPrice;
  if (page.period === 'monthly' || page.period === 'yearly') {

    // adujust the prices based on if the period is monthly and has yearly option vs yearly and has monthly option
    monthlyPrice = page.period === 'monthly' ? basePrice : (page.alternatePayment && page.alternatePaymentAmount > 0) ? scaledIntToDecimal(page.alternatePaymentAmount) : basePrice / 10;
    yearlyPrice = page.period === 'yearly' ? basePrice : (page.alternatePayment && page.alternatePaymentAmount > 0) ? scaledIntToDecimal(page.alternatePaymentAmount) : basePrice * 10;

    formattedPrices.monthlyPrice = formatPrice(monthlyPrice, currency);
    formattedPrices.yearlyPrice = formatPrice(yearlyPrice, currency);
    formattedPrices.monthlyPriceAfterCoupon = formatPrice(monthlyPrice, currency);
    formattedPrices.yearlyPriceAfterCoupon = formatPrice(yearlyPrice, currency);
    let monthlyPriceAfterCoupon = monthlyPrice;
    let yearlyPriceAfterCoupon = yearlyPrice;
    if (stripeCoupon && stripeCoupon.valid) {
      

    
      // Apply the coupon based on its type
      if (stripeCoupon.duration === 'once') {
        monthlyPriceAfterCoupon = getDiscountedAmount(monthlyPrice, stripeCoupon);
        yearlyPriceAfterCoupon = getDiscountedAmount(yearlyPrice, stripeCoupon);
        const duration = 1
        const totalMonthlyPaymentsDiscount = (monthlyPriceAfterCoupon * duration) + (monthlyPrice * (12-duration));
        formattedPrices.totalMonthlyPaymentsDiscount = totalMonthlyPaymentsDiscount
        formattedPrices.yearlyDiscountSavings = formatPrice(totalMonthlyPaymentsDiscount - yearlyPriceAfterCoupon, currency);
        formattedPrices.yearlyDiscountSavingsAmount = Number(totalMonthlyPaymentsDiscount - yearlyPriceAfterCoupon).toFixed(2)

      } else if (stripeCoupon.duration === 'repeating' || stripeCoupon.duration === 'forever') {
        const duration = stripeCoupon.duration === 'repeating' ? stripeCoupon.duration_in_months : 12;
        // handle 
        monthlyPriceAfterCoupon = getDiscountedAmount(monthlyPrice, stripeCoupon);
        yearlyPriceAfterCoupon = getDiscountedAmount(yearlyPrice, stripeCoupon);
        const totalMonthlyPaymentsDiscount = (monthlyPriceAfterCoupon * duration) + (monthlyPrice * (12-duration))
        formattedPrices.totalMonthlyPaymentsDiscount = totalMonthlyPaymentsDiscount
        formattedPrices.yearlyDiscountSavings = formatPrice(totalMonthlyPaymentsDiscount - yearlyPriceAfterCoupon, currency)
        formattedPrices.yearlyDiscountSavingsAmount = Number(totalMonthlyPaymentsDiscount - yearlyPriceAfterCoupon).toFixed(2)

      }

    }

    // Store the formatted prices after applying the coupon
    formattedPrices.monthlyPriceAfterCoupon = formatPrice(monthlyPriceAfterCoupon, currency);
    formattedPrices.yearlyPriceAfterCoupon = formatPrice(yearlyPriceAfterCoupon, currency);
    
    formattedPrices.yearlyDiscountSavings = formattedPrices.yearlyDiscountSavings ? formatPrice(formattedPrices.yearlyDiscountSavings, currency) : formatPrice((monthlyPriceAfterCoupon * 12) - yearlyPriceAfterCoupon, currency);
    formattedPrices.yearlyDiscountSavingsAmount = Number(formattedPrices.yearlyDiscountSavingsAmount || (monthlyPriceAfterCoupon * 12) - yearlyPriceAfterCoupon).toFixed(2)
    
  } else {
    // For non-monthly and non-yearly prices, apply coupon directly to base price
    const discountedPrice = calculateDiscountedPrice(basePrice, stripeCoupon);
    formattedPrices.currentPrice = formatPrice(basePrice, currency);
    formattedPrices.currentPriceAfterCoupon = formatPrice(discountedPrice, currency);
  }

  if (page.period === 'monthly' || page.period === 'yearly' && page.alternatePayment) {
    if (yearlySelected) {
      formattedPrices.currentPrice = formatPrice(formattedPrices.yearlyPrice, currency);
      formattedPrices.currentPriceAfterCoupon = formatPrice(formattedPrices.yearlyPriceAfterCoupon, currency);
    } else {
      formattedPrices.currentPrice = formatPrice(formattedPrices.monthlyPrice, currency);
      formattedPrices.currentPriceAfterCoupon = formatPrice(formattedPrices.monthlyPriceAfterCoupon, currency);
    }
  } else if (page.period === 'monthly') {
    formattedPrices.currentPrice = formatPrice(formattedPrices.monthlyPrice, currency);
    formattedPrices.currentPriceAfterCoupon = formatPrice(formattedPrices.monthlyPriceAfterCoupon, currency);
  
  } else if (page.period === 'yearly') {
    formattedPrices.currentPrice = formatPrice(formattedPrices.yearlyPrice, currency);
    formattedPrices.currentPriceAfterCoupon = formatPrice(formattedPrices.yearlyPriceAfterCoupon, currency);
  
  }

  

  /*
{`${formattedPrices.period !== 'one-time' ? 'Recurring ' : ''}${capitalize(formattedPrices.period)}${formattedPrices.period !== 'one-time' ? ' Subscription' : ''}`}
  */
  if (page.period !== 'one-time') {
    let autoRenewText= `Subscription renews automatically at ${formattedPrices.currentPriceAfterCoupon}.  You can cancel at any time.`
    let selectedPeriod = page.period

    if (page.period === 'yearly' && !page.alternatePayment) {
      selectedPeriod = 'yearly'
    }

    if (page.period === 'monthly' && !page.alternatePayment) {
      selectedPeriod = 'monthly'
    }

    if ((page.period === 'monthly' || page.period === 'yearly') && page.alternatePayment) {
      selectedPeriod = yearlySelected ? 'yearly' : 'monthly'
    }

    let simpleText = `Recurring ${capitalizeFirstLetter(selectedPeriod)} Subscription`
    let planText = `${capitalizeFirstLetter(selectedPeriod)} Plan`
    if (stripeCoupon && stripeCoupon.valid) {
      if (stripeCoupon.duration === 'repeating') {
        if (stripeCoupon.duration_in_months && !yearlySelected) {
            autoRenewText = `Subscription initially renews at ${formattedPrices.currentPriceAfterCoupon}/month for ${stripeCoupon.duration_in_months} months, then increases to ${formattedPrices.currentPrice}. You can cancel at any time.`
            formattedPrices.yearlyRenewText = `Subscription renews automatically at ${formattedPrices.yearlyPrice}. You can cancel at any time.`
        } else {
          autoRenewText = `Subscription renews automatically at ${formattedPrices.currentPrice}. You can cancel at any time.`
        }
      }

      if (stripeCoupon.duration === 'once') {
        if (!yearlySelected) {
          autoRenewText = `Subscription initially renews at ${formattedPrices.currentPriceAfterCoupon}/month for 1 month, then increases to ${formattedPrices.currentPrice}. You can cancel at any time.`
          formattedPrices.yearlyRenewText = `Subscription renews automatically at ${formattedPrices.yearlyPrice}. You can cancel at any time.`
        } else {
          autoRenewText = `Subscription renews automatically at ${formattedPrices.currentPrice}. You can cancel at any time.`
        }
      }
      if (stripeCoupon.duration === 'forever') {
        autoRenewText = `Subscription renews automatically at ${formattedPrices.currentPriceAfterCoupon}. You can cancel at any time.`
      }
      

      
    }
    if (page.customSetting1Enabled === false) {
      autoRenewText = `Time based access.`
      planText = 'Membership'
      switch (formattedPrices.period) {
        case 'monthly':
          simpleText = '1 Month Membership'
          autoRenewText = '1 Month'
          break;
        case 'yearly':
          simpleText = '1 Year Membership'
          autoRenewText = '1 Year'
          
          break;
        case 'semiannual':
          simpleText = '6 Month Membership'
          autoRenewText = '6 Months'
          break;
        case 'quarterly':
          simpleText = '4 Month Membership'
          autoRenewText = '4 Months'
          break;
        case 'weekly':
          simpleText = '1 Week Membership'
          autoRenewText = '1 Week'
          break;
        case 'daily':
          simpleText = '1 Day Membership'
          autoRenewText = '1 Day'
          break;
        case 'biweekly':
          simpleText = '2 Week Membership'
          autoRenewText = '2 Weeks'
          break;
        default:
          break;
      }
      

    }
    formattedPrices.planText = planText
    formattedPrices.simpleText = simpleText
    formattedPrices.autoRenewText = autoRenewText
    if (!yearlySelected)
      formattedPrices.yearlyRenewText = `Subscription renews automatically at ${formattedPrices.yearlyPrice}.  You can cancel at any time.`

      if (page.customSetting1Enabled === false) {
        formattedPrices.yearlyRenewText = ''
      }
      
  } else {
    formattedPrices.simpleText = 'One-time'
  }

  if (stripeCoupon && stripeCoupon.valid) {
    let discountDisplay = "automatic recurring payments"
    
    if (page.customSetting1Enabled === false) {
      discountDisplay = ''
    }


    if (stripeCoupon.amount_off) {
      const currency = (stripeCoupon.currency || 'usd').toUpperCase();
      discountDisplay = `${formatPrice(stripeCoupon.amount_off / 100, currency)} off `
      
    } else if (stripeCoupon.percent_off) {
      discountDisplay = `${stripeCoupon.percent_off}% off `
    }

    if (stripeCoupon.duration === "forever" || stripeCoupon.duration === "once") {
      discountDisplay += stripeCoupon.duration
    } else {
      // repeating
      if (yearlySelected) {
        // if duration on yearly the monthly discount applies only once regardless of duration
      } else {
        discountDisplay += `for ${stripeCoupon.duration_in_months} months`
      }
    }

    
    formattedPrices.discountDisplay = discountDisplay
  }

  

  if (page.trialPeriod) {
    const freeTrialBillingMessage = `You'll only be billed after ${page.trialPeriod} days trial end`
    formattedPrices.autoRenewText = `${freeTrialBillingMessage}. ${formattedPrices.autoRenewText}`
  }
  
  let label = `${formattedPrices.currentPriceAfterCoupon} ${(currency || 'usd').toUpperCase()}`
  // `${price} ${currency}`;

  
  switch (page.period) {

    

    case 'monthly':
      if (page.alternatePayment) {
        label += ` per ${yearlySelected ? 'year' : 'month'}`;
        formattedPrices.period = yearlySelected ? 'yearly' : 'monthly';
      } else {
        label += ' per month';
        formattedPrices.period = 'monthly';
      }
      break;
    case 'yearly':
      if (page.alternatePayment) {
        label += ` per ${page.alternatePayment && yearlySelected ? 'year' : 'month'}`;
        formattedPrices.period = yearlySelected ? 'yearly' : 'monthly';
      } else {
        label += ' per year';
        formattedPrices.period = 'yearly';
      }
      break;
    case 'semiannual':
      label += ' semiannually (6-months)';
      break;
    case 'quarterly':
      label += ' quarterly (3-months)';
      break;
    case 'weekly':
      label += ' per week';
      break;
    case 'daily':
      label += ' per day';
      break;
    case 'biweekly':
      label += ' bi weekly';
      break;
    case 'one-time':
      label += ''
    default:
      break;
  }

  let defaultMsg = `${label} (${formattedPrices.discountDisplay || 'automatic recurring payments'})`
  if (page.customSetting1Enabled === false) {
    defaultMsg = `${formattedPrices.currentPriceAfterCoupon} ${(currency || 'usd').toUpperCase()} for ${formattedPrices.autoRenewText}`
  }


  formattedPrices.recurringPriceLabel = defaultMsg;
  

  // GA4
  
  // console.log('ecom', page)
  formattedPrices.ecom = {
    value: getNumber(formattedPrices.currentPrice),
    items: [
      {
        item_id: page.id,
        item_name: `${page.domain} ${page.offerName || page.channel}`,
        currency: currency,
        item_brand: page.domain,
        item_category: page.period,
        item_list_id: page.id,
        item_list_name: (page.offerName || page.channel),
        index: 0,
        price: getNumber(formattedPrices.currentPrice),
        quantity: 1
      }
    ]
  }
  // console.log('getFormattedPrices', formattedPrices);
  return formattedPrices;
};

export const isColorLight = (color) => {
  if (color?.length == 7) {
    const rgb = [
      parseInt(color.substring(1, 3), 16),
      parseInt(color.substring(3, 5), 16),
      parseInt(color.substring(5), 16),
    ];
    const luminance =
      (0.2126 * rgb[0]) / 255 +
      (0.7152 * rgb[1]) / 255 +
      (0.0722 * rgb[2]) / 255;
    return luminance > 0.5;
  }
  return false;
}

export const fetchVideoThumbnail = (url) => {
  if (url.includes('youtube')) {
    return fetchYouTubeThumbnail(url);
  }
  if (url.includes('vimeo')) {
    return fetchVimeoThumbnail(url);
  }
  return '';
};

export const fetchYouTubeThumbnail = (url) => {
  const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
  // console.log("url.split('v=')", url.split('v='))
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  return thumbnailUrl;
};

export const fetchVimeoThumbnail = (url) => {
  const videoId = url.split('/').pop();
  return `https://vumbnail.com/${videoId}.jpg`;
};

export const validateUrl = (value) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );

export const cleanOffers = (pages) => {

  const o = JSON.parse(JSON.stringify(pages));

  const offers = o.map(page => ({
    offerName: page.offerName,
    businessName: page.businessName,
    price: page.price,
    period: page.period,
    alternatePaymentAmount: page.alternatePaymentAmount,
    alternatePayment: page.alternatePayment,
    id: page.id,
    trialPeriod: page.trialPeriod,
    brandImg: page.brandImg,
    InfluencerImg: page.InfluencerImg,
    currency: page.currency,
    image: page.image,
    about: page.about,
    owner_id: page.owner_id,
    customEmbed: page.customEmbed,
    convertCode: page.convertCode,
    domain: page.domain,
    backgroundColor: page.backgroundColor,
    buttonColor: page.buttonColor,
    textColor: page.textColor,
    inviteType: page.inviteType,
    customTOS: page.customTOS,
    displayCustomTOS: page.displayCustomTOS,
    offerURL: page.offerURL,
    displayMediaEmbed: page.displayMediaEmbed,
    mediaEmbed: page.mediaEmbed,
    mediaAssets: page.mediaAssets || [],
    displayName: page.displayName,
    features: page.features,
    stripeConnectId: page.stripeConnectId,
    status: page.status,
    couponsEnabled: page.couponsEnabled,
    cryptoEnabled: page.cryptoEnabled,
    customSetting1Enabled: page.customSetting1Enabled,
    businessPageEnabled: page.businessPageEnabled,
    discordEnabled: page.discordEnabled,
    slackEnabled: page.slackEnabled,
    telegramEnabled: page.telegramEnabled,
    coinbaseEnabled: page.coinbaseEnabled,
    displayCustomTOS: page.displayCustomTOS,
    customTOS: page.customTOS,
    requireNameEnabled: page.requireNameEnabled,
    channel: page.channel,
    domain: page.domain,
  

  }))
  return offers
}


export const processHTML = (html) => {
  const url = config.get('url')
  const $ = cheerio.load(html)

  $('a').map((i, el) => {
    const href = $(el).attr('href')

    if (href) {
      // Prepend // to all hrefs that have no //
      if (href.indexOf('//') === -1) {
        $(el).attr('href', `//${href}`)
      }

      // Tear off our own domain if the URL also includes a common non-io TLD
      // Only works if our own domain is .io
      if (href.indexOf(url) >= 0 && (/\.com|\.org|\.net/).test(href)) {
        $(el).attr('href', href.replace(url, 'https://'))
      }
    }
  })

  // Add target="_blank" to all anchor elements
  $('a').attr('target', '_blank')

  return $.html()
}

export const generateTagline = (category) => {
  const taglines = {
    art: [
      'Unleash Your Inner Artist in the Best Art Community!',
      'Creativity Knows No Limits – Join the Art Hub!',
      'Where Inspiration Meets Expression – The Art Collective!'
    ],
    'board-games': [
      'Roll the Dice & Have Fun – The Ultimate Board Games Hub!',
      'Play, Strategize, Win – Join the Board Game Masters!',
      'Shuffle Up and Compete with the Best Board Gamers!'
    ],
    business: [
      'Your Network for Success – The Top Business Hub!',
      'Connect, Grow & Succeed – The Business Community for Leaders!',
      'Elevate Your Business Game – Join the Entrepreneurial Network!'
    ],
    design: [
      'Design Beyond Limits – The Creative Community for Designers!',
      'Where Innovation Meets Aesthetics – Join the Design Hub!',
      'Create, Inspire, Transform – The Best Design Community!'
    ],
    'e-commerce': [
      'Scale Your Store to the Next Level – The E-Commerce Network!',
      'Sell More, Earn More – Join the Ultimate E-Commerce Hub!',
      'Build, Market, Thrive – The Best E-Commerce Community!'
    ],
    'fitness-and-health': [
      'Stronger Together – The Ultimate Fitness & Health Community!',
      'Push Your Limits & Stay Motivated – Join the Fit Tribe!',
      'Health, Wellness, and Strength – The Fitness Hub for You!'
    ],
    gaming: [
      'Game On! The Ultimate Gaming Community Awaits!',
      'Play, Win, Repeat – The Home for True Gamers!',
      'Level Up Your Gaming Experience with the Best Players!'
    ],
    music: [
      'Feel the Beat – The Ultimate Music Community!',
      'From Melodies to Masterpieces – Join the Music Hub!',
      'Where Artists & Fans Unite – The Soundwave Community!'
    ],
    'personal-finance': [
      'Master Your Money – The Ultimate Personal Finance Community!',
      'Financial Freedom Starts Here – Join the Money Hub!',
      'Save Smart, Invest Wisely – The Personal Finance Network!'
    ],
    crypto: [
      'HODL Strong! The Ultimate Crypto Enthusiasts’ Community!',
      'Trade, Invest, and Learn – The Future of Crypto Starts Here!',
      'The Blockchain Revolution – Join the Crypto Hub!'
    ],
    'real-estate': [
      'Buy, Sell, Invest – The Best Real Estate Community!',
      'Unlock Property Secrets – Join the Real Estate Network!',
      'Build Your Property Empire – The Real Estate Hub Awaits!'
    ],
    reselling: [
      'Flip, Sell, Profit – The Resellers’ Marketplace!',
      'Turn Small Investments into Big Profits – Join the Reselling Hub!',
      'Smart Selling Starts Here – The Reselling Community Awaits!'
    ],
    'social-media': [
      'Grow, Engage, Influence – The Social Media Community!',
      'Master Social Growth – The Ultimate Influencer Hub!',
      'From Likes to Revenue – The Social Media Mastermind!'
    ],
    sports: [
      'Play Hard, Win Big – The Sports Community for Champions!',
      'Fuel Your Passion for Sports – Join the Athletes’ Network!',
      'Compete, Connect, Celebrate – The Ultimate Sports Hub!'
    ],
    trading: [
      'Trade Smart, Earn Big – The Trading Hub for Investors!',
      'Market Moves Made Easy – Join the Traders’ Network!',
      'Bulls, Bears, and Breakouts – The Trading Community Awaits!'
    ],
    travel: [
      'Explore, Discover, Connect – The Ultimate Travel Community!',
      'From Bucket Lists to Boarding Passes – Join the Travel Tribe!',
      'Where Every Journey Becomes an Adventure – The Travel Hub!'
    ]
  };

  // Convert category to lowercase and check if it exists
  const key = category.toLowerCase();
  if (taglines[key]) {
    return taglines[key][Math.floor(Math.random() * taglines[key].length)];
  } else {
    return 'Join the Best Community!';
  }

};
